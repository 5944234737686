<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      sortable
      :headers="headers"
      :summary-headers="summaryHeaders"
      :show-operations="false"
      :resource-limit="100"
      :resource-query="resourceQuery"
      resource="transactions"
      :custom-resource="revenuesResource()"
      :transform-fetched-data="transformFetchedData"
      :transform-fetched-meta-allowed="false"
      no-meta-check
      :locale="locale"
      show-total-count
      show-filter
      :search-filters="filtersList"
      route-base="/reports/statistics/revenues"
      do-route-filters
      :buttons="computedButtons"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      prune-search-filters
      headers-filterable
      :headers-config="headersConfig"
      class="top-table"
      :class="{ 'pb-0': isWhiteLabel }"
      @loading-error="handleLoadingError"
      @loaded="handleTableLoaded"
      @headers-config="handleHeadersConfig"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    >
      <template #after-table>
        <div class="h-30 comparison-container">
          <el-main v-loading="comparisonLoading" class="sub-table">
            <div class="ml-6 mb-2 text-sm text-gray-800 font-bold">
              {{ formattedComparisonTableRange }}
            </div>
            <th-datatable
              :key="comparisonLoading"
              :headers="summaryHeaders"
              :show-operations="false"
              :table-data="periodComparisonData"
              resource="transactions"
              no-meta-check
              :do-fetch="false"
              :show-total-count="false"
              :paging="false"
              :show-search-filter="false"
              :do-route-filters="false"
              :do-route-false="false"
              block-no-data
            />
          </el-main>
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import pick from 'just-pick'
import { mapGetters } from 'vuex'
import get from 'just-safe-get'
import { differenceInMilliseconds, subMilliseconds } from 'date-fns'
import {
  applyFiltersBeforeRouteEnter,
  getRangeFor,
  getDateTimeFormat,
  formatDateRange
} from '@/utils/date'
import { waitForData } from '@/utils/general'
import { isUnifiedCommerce } from '@/constants'
import { useExportsStore } from '@/store/exports'

const headersConfigPath = 'settings.headerFilters.reports.statistics.revenues'

export default {
  name: 'ReportsStatisticsRevenues',
  metaInfo() {
    return {
      title: this.$t('pages.statistics.revenues.title')
    }
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(get(qs.parse(to.query), 'filter') || {}, [
      'date',
      'register'
    ])
    this.$emit('route-filter', {
      ...elegibleObj,
      register: elegibleObj.register || undefined
    })

    next()
  },

  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      comparisonLoading: false,
      periodComparisonData: [],
      summaryHeaders: [
        {
          field: 'sales_count',
          label: this.$t('pages.statistics.revenues.all.table.sales_count'),
          fallback: '-',
          truncate: true,
          minWidth: 120,
          formatter: (row) => {
            if (!Number.isFinite(row.sales_count)) {
              return '-'
            }
            return row.sales_count
          },
          align: 'right'
        },
        {
          field: 'revenue_gross_products',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_gross_products'
          ),
          fallback: '-',
          truncate: true,
          minWidth: 120,
          formatter: (row) => {
            if (Number.isFinite(row.revenue_gross_products)) {
              return this.$formatCurrency(
                row.revenue_gross_products,
                row.currency
              )
            }
            return '-'
          },
          align: 'right',
          sortType: 'currency'
        },
        {
          field: 'revenue_net_products',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_net_products'
          ),
          fallback: '-',
          truncate: true,
          minWidth: 120,
          formatter: (row) => {
            if (Number.isFinite(row.revenue_net_products)) {
              return this.$formatCurrency(
                row.revenue_net_products,
                row.currency
              )
            }
            return '-'
          },
          align: 'right',
          sortType: 'currency'
        },
        {
          field: 'revenue_gross_services',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_gross_services'
          ),
          fallback: '-',
          truncate: true,
          minWidth: 120,
          formatter: (row) => {
            if (Number.isFinite(row.revenue_gross_services)) {
              return this.$formatCurrency(
                row.revenue_gross_services,
                row.currency
              )
            }
            return '-'
          },
          align: 'right',
          sortType: 'currency'
        },
        {
          field: 'revenue_net_services',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_net_services'
          ),
          fallback: '-',
          truncate: true,
          minWidth: 120,
          formatter: (row) => {
            if (Number.isFinite(row.revenue_net_services)) {
              return this.$formatCurrency(
                row.revenue_net_services,
                row.currency
              )
            }
            return '-'
          },
          align: 'right',
          sortType: 'currency'
        }
      ],
      // NOTE: the header list is quite sensitive to adding formatters. We discovered perfomance issues in massive data scenerios that are
      // are adressed with .transformFetchedData in this component
      headers: [
        {
          field: '_date_label',
          label: this.$t('pages.statistics.revenues.all.table.date'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          sortType: 'date'
        },
        {
          field: '_branch_label',
          label: this.$t('pages.statistics.revenues.all.table.branch'),
          minWidth: 140,
          fallback: '-',
          truncate: true
        },
        {
          field: '_register_label',
          label: this.$t('pages.statistics.revenues.all.table.register'),
          minWidth: 140,
          fallback: '-',
          truncate: true
        },
        {
          field: 'sales_count',
          label: this.$t('pages.statistics.revenues.all.table.sales_count'),
          minWidth: 140,
          fallback: '-',
          truncate: true
        },
        {
          field: 'revenue_gross_products',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_gross_products'
          ),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.revenue_gross_products)) {
              return this.$formatCurrency(
                row.revenue_gross_products,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'revenue_net_products',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_net_products'
          ),
          fallback: '-',
          minWidth: 140,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.revenue_net_products)) {
              return this.$formatCurrency(
                row.revenue_net_products,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'revenue_gross_services',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_gross_services'
          ),
          fallback: '-',
          minWidth: 190,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.revenue_gross_services)) {
              return this.$formatCurrency(
                row.revenue_gross_services,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'revenue_net_services',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_net_services'
          ),
          fallback: '-',
          minWidth: 180,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.revenue_net_services)) {
              return this.$formatCurrency(
                row.revenue_net_services,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'share_of_total',
          label: this.$t(
            'pages.statistics.revenues.all.table.revenue_share_of_total'
          ),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.share_of_total)) {
              return (row.share_of_total * 100).toFixed(2) + '%'
            } else {
              return '-'
            }
          },
          sortType: 'number'
        }
      ],
      hiddenFilters: [],
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_statistics:revenues:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: ({ handleDownload, resourceOptions }) => {
            this.handleExport({ handleDownload, resourceOptions })
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      timeZone: 'Config/getTimeZone',
      localConfiguration: 'Config/getLocalConfiguration',
      currentLocation: 'Config/getCurrentLocation',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    headersConfig() {
      return get(this.localConfiguration, headersConfigPath) || {}
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter
      return parsedQuery || {}
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    },
    filtersList() {
      const list = [
        {
          name: 'register',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.transactions.all.table.register'),
          options:
            this.resources.registersMap &&
            Array.from(this.resources.registersMap)
              .filter(([, r]) => Number.isFinite(r.register_number)) // needed to prevent odd default behavior
              .map(([, r]) => ({
                value: r.id,
                label: this.$formatRegister(r),
                key: r.id
              })),
          filterable: true
        },
        {
          name: 'staff',
          type: 'remote-search-select',
          label: this.$t('pages.transactions.all.filters.staff.label'),
          resource: 'staff',
          computeName: (staff) =>
            this.$formatStaff(staff, ['staff_number', 'fullName'], ' - '),
          modifyQuery: (q) => ({
            q,
            fields: ['staff_number', 'lastname', 'firstname']
          })
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['date_start', 'date_end'],
          type: 'daterange',
          dateTimeMode: true,
          label: this.$t('pages.transactions.all.filters.date.label'),
          closable: false,
          autoClose: false,
          formatValue: (value) => formatDateRange(value, getDateTimeFormat()),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            date_start: filterObject.start,
            date_end: filterObject.end
          })
        }
      ]

      return list.filter((f) => !this.hiddenFilters.includes(f.name))
    },
    resourceQuery() {
      return {
        query: {
          register: this.parsedQuery.register || undefined,
          staff: this.parsedQuery.staff || undefined,
          branch: this.currentLocation || undefined,
          limit: 100,
          timezone: this.timeZone
        }
      }
    },
    resourceQueryRangeOffset() {
      return {
        query: {
          register: this.parsedQuery.register || undefined,
          staff: this.parsedQuery.staff || undefined,
          branch: this.currentLocation || undefined,
          report_map_item: 'summary',
          ...this.subTableRange,
          timezone: this.timeZone
        }
      }
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    subTableRange() {
      const dateStart = get(this.parsedQuery, 'date.start')
      const dateEnd = get(this.parsedQuery, 'date.end')
      const range = differenceInMilliseconds(
        new Date(dateEnd),
        new Date(dateStart)
      )

      return {
        date_start: dateStart
          ? subMilliseconds(new Date(dateStart), range + 1).toISOString()
          : undefined,
        date_end: dateStart
          ? subMilliseconds(new Date(dateStart), 1).toISOString()
          : undefined
      }
    },
    formattedComparisonTableRange() {
      return this.$date.formatDateRange({
        start: this.subTableRange.date_start,
        end: this.subTableRange.date_end
      })
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },

  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.statistics.revenues.title')
        })
      })
    },
    revenuesResource() {
      const inst = th.analyticsHandlers().analytics.reports
        .AnalyticsReportsRevenuesGrouped
      return inst
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    handleVatHeader(keyArr) {
      const vatArr = keyArr
        .filter((item) => item && item.indexOf('revenue_net_vat_') > -1)
        .map((item) => {
          return {
            key: item,
            vatPercentage: Number(item.match(/[0-9]+(\.[0-9]+)?/)[0])
          }
        })
        .sort((a, b) => {
          return a.vatPercentage > b.vatPercentage
        })
        .forEach((item) => {
          this.headers.push({
            field: `_${item.key}`,
            label: this.$t(
              'pages.statistics.revenues.all.table.revenue_net_vat_column',
              { vatPercentage: item.vatPercentage }
            ),
            minWidth: 160,
            fallback: '-',
            align: 'right',
            truncate: true
          })

          this.summaryHeaders.push({
            field: `_${item.key}`,
            label: this.$t(
              'pages.statistics.revenues.all.table.revenue_net_vat_column',
              { vatPercentage: item.vatPercentage }
            ),
            minWidth: 160,
            fallback: '-',
            align: 'right',
            truncate: true,
            formatter: (row) => {
              if (Number.isFinite(row[item.key])) {
                return this.$formatCurrency(row[item.key], row.currency)
              }
              return '-'
            },
            sortType: 'currency'
          })
        })

      return vatArr
    },
    // NOTE: this is a perfomance optmisation in order not to parse in formatters, which seems to be costly in massive data scenarios.
    // The gist is: pre-digest strings, so the call stacks get thinner later. This mutates actual data inside the table
    async transformFetchedData(data) {
      await waitForData(
        () => this.resources.branchesMap && this.resources.registersMap
      )
      let keys
      if (data.length) {
        keys = Object.keys(data[0])
        this.handleVatHeader(keys)
      }

      return data.map((item) => {
        if (!item.period) {
          item._date_label = '-'
        } else {
          item._date_label = this.$date.formatDateWithTimezone(item.period)
        }

        const branch = this.resources.branchesMap.get(item.branch)
        const register = this.resources.registersMap.get(item.register)
        if (branch) {
          item._branch_label = this.$formatBranch(branch)
        } else {
          item._branch_label = '-'
        }
        if (register) {
          item._register_label = this.$formatRegister(register)
        } else {
          item._register_label = '-'
        }

        return item
      })
    },

    async handleExport({ resourceOptions }) {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')
      const query = {
        ...resourceOptions.query,
        format: 'csv',
        filename_prefix: this.$t(
          'pages.statistics.revenues.exports.filename_prefix'
        ),
        timezone: this.timeZone
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlersV3()
          .analytics.reports.AnalyticsReportsRevenues.export({ query })

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.statistics.revenues.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV3',
              path: 'analytics.reports.AnalyticsReportsRevenues',
              handler: 'export',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.statistics.revenues.title')
          })
        })
      }
    },
    async handleTableLoaded() {
      this.comparisonLoading = true
      try {
        const { summary = [] } = await this.revenuesResource().getAll(
          this.resourceQueryRangeOffset
        )
        this.periodComparisonData = summary
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.comparisonLoading = false
      }
    }
  }
}
</script>

<style scoped>
.top-table {
  overflow: auto;
  padding-bottom: 1rem;
}

.top-table :deep(.content) {
  min-height: 300px;
}

.sub-table {
  margin: 0;
  padding: 0;
}

.sub-table :deep(.content) {
  min-height: unset !important;
}

.sub-table :deep(.table-actions) {
  display: none !important;
}

.sub-table :deep(.card-content) {
  margin-top: 0 !important;
}

.sub-table :deep(.mx-6.mt-6.mb-0) {
  margin: 0 !important;
}
</style>
